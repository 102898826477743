div.profile-tab.tab-pane {
    width: 60vw !important;
}

span.arrow_back {
    cursor: pointer;
}

div.col-1 {
    width: 30px;
    div.vertical-row {
        height: 600px;
    }
}
