* {
    box-sizing: border-box;
}

div.App {
    height: 100vh;
}
div.menu {
    position: "absolute";
    top: 0;
    left: "0%";
    background-color: "#fff";
    box-shadow: "0px 0px 5px rgba(0,0,0,0.2)";
    border-radius: "5px";
    padding: "5px 0";
    z-index: 2;

    width: 200px;

    button {
        border: none;
        border-radius: 0;
        width: 100%;
        color: #1f1f1f;
        display: flex;
        justify-content: start;
        align-items: center;
    }

    button:hover {
        path {
            fill: #fff;
        }
    }
}

@keyframes shake {
    0% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    10% {
        transform: translate(-1px, -1px) rotate(-1deg);
    }
    20% {
        transform: translate(-1px, 0px) rotate(1deg);
    }
    30% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    40% {
        transform: translate(1px, -1px) rotate(1deg);
    }
    50% {
        transform: translate(-1px, 1px) rotate(-1deg);
    }
    60% {
        transform: translate(-1px, 1px) rotate(0deg);
    }
    70% {
        transform: translate(1px, 1px) rotate(-1deg);
    }
    80% {
        transform: translate(-1px, -1px) rotate(1deg);
    }
    90% {
        transform: translate(1px, 1px) rotate(0deg);
    }
    100% {
        transform: translate(1px, -1px) rotate(-1deg);
    }
}

.shake {
    animation: shake 0.5s;
    animation-iteration-count: infinite;
}
