div.add-options {
    display: inline-block;

    button.btn-outline-secondary {
        color: #1f1f1f !important;
    }

    button.btn-outline-secondary:hover {
        color: #fff !important;
    }

    button.add-drive-btn {
        padding: 14px 20px 14px 16px;
        border-radius: 15px;
        column-gap: 8px !important;
        color: #1f1f1f !important;
        span {
            font-weight: 500;
        }
    }

    button.add-drive-btn:hover {
        background-color: #f0f4f9;
    }

    button.add-drive-btn:focus {
        outline: none;
        box-shadow: none;
    }
}
