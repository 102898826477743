div.spinner-over-modal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    .spinner-border {
        width: 3rem;
        height: 3rem;
        color: #ffffff;
    }
}

div.my-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 260px;
    width: 260px;
    border: 8px solid #000000;
    border-top: 8px solid transparent;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
}

div.black-eagle {
    height: 200px;
    width: 200px;
    background: url("../../assets/black_eagle.svg") no-repeat center local;
    background-size: 100%;
}

/* Animação do spinner */
@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
        transform: translate(-50%, -50%) rotate(360deg);
    }
}
