div.blanc {
    color: white;
}

div.col.new-student {
    background-color: red;
    align-items: end;
    justify-items: end;
    align-content: end;
}
