.loading-animation {
    list-style: none;
    padding: 0;
}

.loading-animation li {
    display: flex;
    align-items: center;
    margin-bottom: -15px;
}

.loading-animation .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #333;
    margin-right: 5px;
    animation: bounce 1.5s infinite ease-in-out;
}

.loading-animation .dot:nth-child(1) {
    animation-delay: 0.1s;
}

.loading-animation .dot:nth-child(2) {
    animation-delay: 0.3s;
}

.loading-animation .dot:nth-child(3) {
    animation-delay: 0.5s;
}

@keyframes bounce {
    0%,
    100% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-15px);
    }
}
