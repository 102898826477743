button.arrow-double {
    background-color: #0d6efd;
    height: 48px;
    width: 48px;
    border-radius: 50%;

    border: none;
    transition: left 0.3s ease;
    position: absolute;
    top: 30%;
    left: 70%;
    z-index: 2;
    img {
        transition: transform 0.3s ease; /* Adiciona uma transição suave para a rotação */
    }
}

div.sidebar {
    width: 80px;
    height: 100vh;
    position: relative;
    transition: width 0.3s ease; /* Adiciona uma transição suave para a rotação */

    a.title {
        white-space: nowrap;
        max-width: 100%;
        text-align: center;
        color: #fff;
        text-decoration: none;
    }

    a.nav-link {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        column-gap: 12px;
    }

    a.nav-link.active {
        path {
            fill: #fff;
        }
    }
}

div.sidebar.expanded {
    width: 280px;

    a.title {
        text-align: start;
    }

    button.arrow-double {
        left: 88%;

        img {
            transform: rotateY(180deg); /* Gira 180 graus em torno do eixo Y */
        }
    }
}
