#table-wrapper {
    position: relative;
}
#table-scroll {
    height: 62vh;
    overflow: auto;
}
#table-wrapper table {
    width: 100%;
}
