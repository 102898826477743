li.folder-item {
    div.three-item {
        height: 24px;
        width: 24px;
        cursor: pointer;

        border-radius: 50%;
        transition: background-color 0.3s ease;
    }

    div.three-item:hover {
        background-color: rgba(209, 211, 213, 0.8); /* por exemplo */
    }

    div.folder-name {
        cursor: pointer;
    }

    text-decoration: none;
    list-style-type: none;
    background-color: #f0f4f9;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.badge {
        display: flex;
        align-items: center;
        padding: 8px 12px;
    }
    width: 300px;
    height: 50px;

    border-radius: 5px;

    button {
        background: none;
        border: none;
    }
}
div.items-count-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 8px;
    width: 24px;
    height: 20px;
    span.items-count {
        font-size: 12px;
        color: #fff;
    }
}
