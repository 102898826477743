.login-page {
    height: 100vh;
    display: flex;

    align-items: center;
    justify-items: center;
    justify-content: center;

    flex-direction: column;

    background:#2C7B95;
    
    div.login-card{
        height: 380px;
        width: 300px;

    

        div.ear-logo{
            height: 300px;
            width: 300px;
            background: url('../../assets/eagle.svg') no-repeat center local;
            background-size:100%;
        }

    }
}

