li.folder-file {
    text-decoration: none;
    list-style-type: none;
    background-color: #f0f4f9;
    padding: 6px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span.badge {
        display: flex;
        align-items: center;
        padding: 8px 12px;
    }
    width: 300px;
    height: 50px;

    border-radius: 5px;

    button {
        background: none;
        border: none;
    }

    div {
        justify-content: space-between;
        align-items: center;
        img {
            width: 16px;
            height: 16px;
        }
    }
}
