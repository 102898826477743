div.student-card {
    color: #3e4143;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    min-width: 300px !important;
    max-width: 300px !important;

    // min-height: 160px !important;
    // max-height: 160px !important;

    div.task-count {
        // path {
        //     fill: #fff;
        // }
    }

    div.task-count-success {
        path {
            fill: #198754;
        }
        span.text-secondary {
            color: #198754 !important;
        }
    }

    div.profile {
        align-items: center;
        justify-content: start;
        width: 100%;
        img {
            height: 60px;
            width: auto;

            border-radius: 50%;
        }
        // h5 {
        //     text-align: center;
        // }
    }

    div.card-body {
        width: 100%;
    }

    button.btn-arrow {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        padding-left: 16px;
        padding-right: 12px;
    }
}
