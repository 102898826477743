div.student-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    align-items: start;
    justify-content: start;
    height: 400px;

    overflow-y: auto;
}
