div.modal-container-background {
    div.toggle-btn {
        label,
        input {
            cursor: pointer;
        }
    }

    position: absolute;
    z-index: 2;
    width: 100vw;
    height: 100vh;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: #0f0f0f3e;

    display: flex;
    justify-content: center;
    align-items: center;

    div.file-full-screen-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        column-gap: 36px;

        padding: 6px 3px;
        position: absolute;
        top: 0;

        img {
            width: 48px;
            height: auto;
        }

        div {
            display: flex;
            align-items: center;
            justify-content: space-between;
            column-gap: 3px;
            h4 {
                margin: 0;
            }
        }
    }

    div.lsa-options {
        height: 200px;

        background-color: red;

        position: absolute;
        top: 25px;
        left: 5px;

        button {
            //box shadow
        }
    }
}
