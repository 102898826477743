div.folder-tree {
    span {
        cursor: pointer;
    }
    span.folder-tree-item:hover {
        color: #212529 !important;
    }
}

div.documents-list {
    overflow-y: auto;
    overflow-x: auto;
    height: 400px;

    ul {
        display: grid;
        grid-template-columns: repeat(2, auto);
        padding: 0;
        row-gap: 12px;
        column-gap: 6px;
    }

    div.no-data {
        flex-direction: column;

        img {
            width: 30%;
            height: auto;
        }
    }
}
